@import url('https://fonts.googleapis.com/css?family=Montserrat');
.App {
  text-align: center;
  font-family: 'Montserrat' !important;
  color: #3e387a
}

.main {
  display: flex;
  min-height: 100vh;
  
}

.nav{
  height: 250px;
  border-bottom: #3e387a solid 2px;
  overflow: hidden;
}

.nav-img-cont{
  width: 100%;
  align-items: center;
  margin-right: auto;
  margin-left: auto;
}

.nav-img{
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: 200px;
  width: 100%;
  max-width: 1000px;
  
}
.main-text{
  font-size: large;
  width: 80% !important;
  margin-left: auto;
  margin-right: auto;
}

.nav-menu{
  width: 90%;
  display:flex;
  justify-content: space-around;
  margin-right: auto;
  margin-left: auto;
}

/*resize header per screen*/
@media (max-width: 850px) {
  .nav-menu{
      position: relative;
      top: -10%;
  }
  .nav{
      height: 220px;
  }
}

@media (max-width: 600px) {
  .nav-menu{
      position: relative;
      top: -20%;
  }
  .nav{
      height: 200px;
  }
  
}

@media (max-width: 420px) {
  .menu-link{
          font-size: small;
      }
      .menu-link-don{
          font-size: small;
      }
}

.menu {
  width:22%;
  border-right: solid #3e387a 2px;
}

.menu-head {
  display: flex;
  margin-bottom: 10px;
}

.menu-links {
  font-size: large;
  padding: 10%;
  text-align: left;
}

.menu-link {
  display: block;
  text-decoration: none;
  margin-left: 5px;
  margin-bottom: 15px;
  padding: 3px;
  color: #3e387a;
}


.menu-link-don {
  display: block;
  text-decoration: none;
  margin-left: 5px;
  margin-bottom: 15px;
  background-color: #3e387a;
  color: white;
  padding: 3px;
  border-radius: 10%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.16);
}

.menu-link:hover{
  color: #8ef3f7;
}

.menu-link-don:hover{
  color: #8ef3f7;
}

.header-banner {
  display: inline-block;
  text-decoration: none;
  margin-top: 20px;
  background-color: #3e387a;
  color: white;
  padding: 3px 6px 3px 6px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.16);
}

.header-banner:hover{
  color: #8ef3f7;
}

.home-img{
  width: 100%;
  padding-top: 10%;
  margin-top: -10%;
  margin-bottom: -10%;
}


/* deploy -> "/home/ubuntu/helpinghands/client/public/bg3.png" */
.content-home {
  padding: 0% 5% 5% 5%;
  width: 100%;
  background-image: url("../public/bg3.png");
  background-size: cover;
}

.content-local {
  padding: 5%;
  width: 100%;
  background-image: url("../public/bg3.png");
  background-size: cover;
}

.content-donate {
  width: 100%;
  background-color:#8ef3f7;
  background-image: url("../public/bg3.png");
  background-size: cover;
  
}

.header-wrapper {
  margin: auto;
  margin-top: 5%;
  width: 80%;
  padding: 2%;
}

.header-wrapper p{
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}

.cash{
  margin-top: 5%;
  font-size: small;
}

.column-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 5%;
  padding-top: 1%;
}

.card-body-wrap {
  white-space: pre-wrap;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%3e387a' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%3e387a' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.car-img{
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: 500px;

  max-width: 1200px !important;
  margin-right: auto;
  margin-left:auto;
  margin-top: 3%;
}

.car-but{
  color: #3e387a !important;
}

.car-ind{
  color: #3e387a;
}
.in-kind {
  margin: 5%;
  flex: 40%;
}

.in-kind-table {
  margin-top: 5%;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.donate{
  margin: 5%;
  flex: 40%;
}

@media (max-width: 950px) {
  .donate, .in-kind {
  flex: 80%;
  }
}

@media (min-width: 1000px){
  .masonry-wrapper{
      max-width: 875px;
      margin: auto;
  }
}

@media (min-width: 1500px){
  .masonry-wrapper{
      max-width: 1200px;
      margin: auto;
  }
}

@media (max-width: 700px){
  .masonry-wrapper{
      max-width: 450px;
      margin: auto;
  }
}

.story-card-title{
  margin-bottom: 8% !important;
}

.header-img {
  padding: 10%;
  width: 100%;
}

.mission {
  padding: 8%;
  margin-top: 3%;
  text-align: left;
}

.mission-title {
  margin-left: 10%;
}
.cropped{
  overflow: hidden;
}

.about-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.story {
  flex: 1;
}

.story-wrap {
  background-color: rgba(255,255,255,0.5);
  margin-bottom: 4%;
  padding: 2%;
}

.board {
  margin-top: 10%;
  display: block;
  flex: 1;
}

.contact {
  flex: 1;
  margin-top: 10%;
}

.contact-body {
  margin: 0;
  font-size: smaller;
}

.header-img {
  width: 100%;
}

.headline-local {
  padding: 10% 10% 0% 10%;
  text-align: left;
}

.cards {
  display: flex;
  width: 95%;
  margin: auto;
}

.card-local {
  background-color: white;
  border-radius: 25px;
  width: 30%;
  padding: 2%;
  margin: 2%;
  height: 150px;
  border: solid #3e387a 2px;
  text-align: left;
}

.bullet {
  font-size: smaller;
  margin-bottom: 10%;
}

.icon {
  width: 50%;
}

.delete {
  color: red;
}

.story-body {
  margin: auto;
  margin-top: 4%;
  width: 90%;
}

.card-inline {
  margin: 2%;
}

.flex-container {
  display: flex;
}

tr:nth-child(even) {
  background-color: #7563a999;
}

tr:nth-child(odd) {
  background-color: #746cae80;
}

.stripe-container {
  margin: auto;
  padding: 3%;
  align-items: center;
  background-color: #8ef3f7;
}

.payment-button {
  margin-top: 10% !important;
}

.btn-outline-primary {
  margin: 5px !important;
  background-color: #3e387a !important;
  color: white !important;
  border-color: #3e387a !important;
}

.btn-outline-primary:hover {
  background-color: white !important;
  border-color: #3e387a !important;
  color: #3e387a !important ;
}

.card-deck {
  margin-top: 5%;
  display: flex;
  justify-content: space-around;
}

.card-deck2 {
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
  display: flex;
  justify-content: space-around;
}

.board-title{
  margin-bottom: 5%;
}

.board-card {
  align-items: center;
  width: 100%;
  font-size: x-small;
  background-color: rgba(255,255,255,0.5) !important;
}

.board-card2 {
  align-items: center;
  width: 23%;
  font-size: x-small;
  background-color: rgba(255,255,255,0.5) !important;
}

.board-image {
  margin-top: 10%;
  max-width: 60%;
}

.card-header {
  background-color: rgba(255, 255, 255, 0.7) !important;
}

.local-card {
  margin: 1%;
  background-color: rgba(255, 255, 255, 0.212) !important;
}

.head-flex {
  display: flex;
  justify-content: space-between;
}

.flex {
  display: flex;
}

.smaller {
  font-size: smaller;
}

.donate-price {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 5%;
  font-size: medium;
  text-align: center;
}

.price {
  margin: 1%;
}

.story-card {
  width: 40%;
  display: inline-block;
}

.card-columns {
  margin-top: 10%;
  display: flex;
  justify-content: space-evenly;
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
}
.my-masonry-grid_column {
  padding: 2.5%; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: rgba(255, 255, 255, 0.7);
  margin-bottom: 30px;
}

.my-card {
  border-radius:0% 0%  2% 2% !important;
  border: #3e387a solid 1px !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.16);
}

.card-img-top {
  border-radius:0% 0%  0% 0% !important;
}

.card-title {
  margin: auto;
  font-weight: bold;
}

.card-subtitle {
  font-size: medium;
}

.flip-card {
  background-color: transparent !important;
  height: 300px !important;
  min-width: 250px;
  max-width: 350px;
  perspective: 500px !important; /* 3D effect */
}

/* This container is needed to position the front and back side */
.flip-inner {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  text-align: center !important;
  transition: transform 0.8s !important;
  transform-style: preserve-3d !important;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-inner {
  transform: rotateY(180deg) !important;
}

/* Position the front and back side */
.flip-front, .flip-back {
  align-items: center;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  -webkit-backface-visibility: hidden !important; /* Safari */
  backface-visibility: hidden !important;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1);
}

/* Style the front side (fallback if image is missing) */
.flip-front {
  background-color: rgba(255, 255, 255, 0.85) !important;
  color: #3e387a !important;
  padding: 10%;
  margin: auto;
  text-align: center;
}

/* Style the back side */
.flip-back {
  text-align: center;
  align-items: center;
  color: white;
  background-color: #7563A9 !important;
  transform: rotateY(180deg) !important;
}

.don-wrap {
  margin-top: 5%;
}

.inp-wrap {
  display: block !important;
  padding: 3%;
  width: 50%;
}

.don-input {
  display: block !important;
  border: none;
  width: 50%;
  background-color:rgba(255, 255, 255, 0.85);
  border-bottom: solid 1px #3e387a;
}

.area-input {
  background-color:rgba(255, 255, 255, 0.85);
  border: none;
  border-bottom: solid 1px #3e387a;
  width: 70%;
}

.don-area {
  padding: 3%;
}

.inp-flex {
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 10px;
}

.inp-dynamic {
  display: flex !important;
  justify-content: space-between;
  margin-bottom: 10px;
}

.area-flex {
  display: flex !important;
  justify-content: space-between;
}

@media (max-width: 700px) {
  .inp-dynamic{
      display: block !important;
  }

  .inp-wrap{
      width: 100%;
  }
  .inp-flex{
      justify-content: space-between;
  }
}

@media (max-width: 1200px) {
  .board-card-title{
      font-size: small !important;
  }
  .board-card-subtitle{
      font-size: small !important;
  }
}

.payment-button {
  height: 20% !important;
}

.my-card-text {
  margin: 1%;
  height: 80%;
}

.donate-cta{
  margin-bottom: 7%;
}

.card-element{
  margin-top: 5%;
}

.paypal-wrap{
  margin-bottom: 15%;
}

.donate-head{
  margin-bottom: 7%;
}

.qr-button{
  margin-top: 5%;
  width: 60%;
  border: none;
  outline: 0;
}

.qr-code{
  width: 100%
}
